import type { ReactNode } from 'react'

import { styled } from 'styled-components'

import { Button, FormattedMessage } from '@b-stock/bstock-react'
import { designColors } from '@b-stock/bstock-react/theme'

import FilterCheckbox from '@components/SearchFilters/shared/FilterCheckbox'

type OnlyableCheckboxProps = {
  displayName: ReactNode
  checked: boolean
  onChange: () => void
  onOnly: () => void
  disabled?: boolean
}

const StyledOnlyButton = styled(Button)`
  margin-left: 4px;
  && {
    height: 22px;
    min-width: 40px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 6px;
  align-items: center;

  &:hover {
    background: ${designColors.primary.hoverOverlay};
    color: ${designColors.primary.default};
  }

  ${StyledOnlyButton} {
    visibility: hidden;
  }

  &:hover ${StyledOnlyButton} {
    visibility: visible;
  }
`

const OnlyableCheckbox = ({
  displayName,
  checked,
  onChange,
  onOnly,
  disabled,
}: OnlyableCheckboxProps) => (
  <li>
    <Container>
      <FilterCheckbox
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        label={displayName}
      />
      <StyledOnlyButton size="small" onClick={onOnly} appearance="secondary">
        <FormattedMessage id="Filters.checkbox.selectOnly" />
      </StyledOnlyButton>
    </Container>
  </li>
)

export default OnlyableCheckbox
