import type * as React from 'react'

import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { Button, FormattedMessage } from '@b-stock/bstock-react'

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 0.2rem;
  font-size: 0.8rem;
`
type SeeMoreProps = {
  showMore: boolean
  handleShowMore: () => void
}

const SeeMoreButton: React.FC<SeeMoreProps> = ({
  showMore,
  handleShowMore,
}) => (
  <Button appearance="plainTertiary" type="button" onClick={handleShowMore}>
    {showMore ? (
      <>
        <FormattedMessage id={'Filters.actions.showLess'} />
        <StyledIcon icon={faChevronUp} />
      </>
    ) : (
      <>
        <FormattedMessage id={'Filters.actions.showMore'} />
        <StyledIcon icon={faChevronDown} />
      </>
    )}
  </Button>
)

export default SeeMoreButton
