import { useIntl } from 'react-intl'

import CategoricalFilter from '../../shared/CategoricalFilter'
import {
  useFilterOptions,
  createCategoricalAppliedComponent,
} from '../../shared/filterUtils'
import type { AuctionFilter } from '../../types'

// warehouseLocation returns as "countryCode/provinceCode/city" (es: "US/CA/San Jose")
// this method formats location to:
//   "city, provinceCode" for US and Canada
//   "city, countryCode" for all other countries
const toDisplayName = (option: string) => {
  const [countryCode, provinceCode, city] = option.split('/')
  const formattedName = ['US', 'CA'].includes(countryCode)
    ? `${city}, ${provinceCode}`
    : `${city}, ${countryCode}`

  return <span>{formattedName}</span>
}

const WarehouseLocationFilter: AuctionFilter<Set<string>> = ({
  onChange,
  desktopScreen,
  items: itemsData,
  availableItems: availableItemsData,
  value,
}) => {
  const intl = useIntl()
  const items = useFilterOptions(itemsData, toDisplayName)
  const availableItems = useFilterOptions(availableItemsData, toDisplayName)

  return (
    <CategoricalFilter
      items={items}
      availableItems={availableItems}
      selected={value}
      name={intl.formatMessage({
        id: 'Filters.warehouseLocation',
      })}
      onChange={onChange}
      desktopScreen={desktopScreen}
    />
  )
}

WarehouseLocationFilter.getOwnProps = ({ state, data, updateFilters }) => {
  return {
    items: data?.allFilters?.['Warehouse Location'] || {},
    availableItems: data?.availableFilters?.['Warehouse Location'] || {},
    value: new Set(state.filters.warehouseLocation),
    onChange: (value: Set<string>) =>
      updateFilters({ warehouseLocation: [...value] }),
  }
}

WarehouseLocationFilter.label = 'Filters.warehouseLocation'

WarehouseLocationFilter.Applied = createCategoricalAppliedComponent(
  'warehouseLocation',
  WarehouseLocationFilter.label,
  toDisplayName
)

export default WarehouseLocationFilter
