import getConfig from 'next/config'

import { useAuthState } from '@b-stock/bstock-next'
import { useAnalytics as useAnalyticsShared } from '@b-stock/bstock-react'

const useAnalytics = () => {
  const {
    publicRuntimeConfig: { REACT_APP_SEGMENT_WRITE_KEY },
  } = getConfig() as {
    publicRuntimeConfig: { REACT_APP_SEGMENT_WRITE_KEY?: string }
  }

  const authState = useAuthState()
  const accountId = authState?.person.accountId
  const personId = authState?.person._id
  const segmentKey =
    REACT_APP_SEGMENT_WRITE_KEY ?? 'dEuLO252MjKUyHynJkJHKb0hspJd2QXl'

  return useAnalyticsShared({
    accountId,
    personId,
    segmentKey,
  })
}

export default useAnalytics
