// This entire file is deprecated
/* eslint-disable @typescript-eslint/no-deprecated */
import { useMemo } from 'react'
import type * as React from 'react'

import { useAuctionSearch, PAGE_SIZE } from '@components/AuctionSearchProvider'
import Pagination, { makeItemComponent } from '@components/Pagination'

type Props = {
  className?: string
}

/**
 * @deprecated Use the Pagination component from bstock-react
 */
const SearchPagination: React.FC<Props> = ({ className }) => {
  const { state, data, setPage } = useAuctionSearch()
  const pageCount = Math.ceil((data?.total || 1) / PAGE_SIZE)

  const ItemComponent = useMemo(
    () => makeItemComponent((page) => `/?p=${page}`, setPage),
    [setPage]
  )

  return (
    <Pagination
      className={className}
      currentPage={state.page}
      pageCount={pageCount}
      ItemComponent={ItemComponent}
    />
  )
}

export default SearchPagination
