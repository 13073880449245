import type * as React from 'react'
import { useEffect, useState } from 'react'

import { styled } from 'styled-components'

import { FormattedMessage, Modal } from '@b-stock/bstock-react'
import { designColors, shadows, typography } from '@b-stock/bstock-react/theme'

import { StyledScroll } from '@components/scroll'
import { AlphabeticalFilterContent } from '@components/SearchFilters/filters/SellerFilter/SellerFilterModal'
import {
  Header,
  ModalStatistic,
  TotalSelected,
} from '@components/SearchFilters/shared/Modal'
import OnlyableCheckbox from '@components/SearchFilters/shared/OnlyableCheckbox'
import SeeMoreButton from '@components/SearchFilters/shared/SeeMoreButton'

type RecordType = {
  displayName: React.ReactNode
  key: string
}

type RecordTypeWithDisable = {
  displayName: React.ReactNode
  key: string
  disabled: boolean
}

type CategoricalFilterProps = {
  items: RecordType[]
  availableItems: RecordType[]
  selected: Set<string>
  onChange: (value: Set<string>) => void
  name?: string
  desktopScreen?: boolean
  customModal?: (param: () => void) => React.ReactNode
}

const ModalContent = styled.ol`
  max-height: 25rem;
  overflow: auto;
  ${shadows.popovers};
`

const SelectedCategories = styled.div`
  ${typography.body3};
  color: ${designColors.neutral.veryDarkGray};
`

const List = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  label > span {
    text-transform: capitalize;
  }
`

const ModalList = styled(List)`
  display: grid;
  grid-template-columns: repeat(3, 250px);
  max-height: 64vh;
  overflow: auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 250px);
  }

  ${StyledScroll}
`

export const splitItems = (
  items: Array<RecordType>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  availableItems: Array<RecordType>
): {
  itemsCount: number
  displayMore: Array<RecordTypeWithDisable>
  displayItems: Array<RecordTypeWithDisable>
  displayModal: boolean
} => {
  const itemsCount = items.length
  let displayMore: Array<RecordTypeWithDisable> = []
  let displayItems: Array<RecordTypeWithDisable> = []

  //TODO: handle showing available filters

  const displayModal = itemsCount > 16
  if (itemsCount <= 8) {
    displayItems = items
      .slice(0, 5)
      .map((item) => ({ ...item, disabled: false }))
    displayMore = items
      .slice(5, 8)
      .map((item) => ({ ...item, disabled: false }))
  } else if (itemsCount > 8 && itemsCount <= 16) {
    displayItems = items
      .slice(0, 5)
      .map((item) => ({ ...item, disabled: false }))
    displayMore = items
      .slice(5, 16)
      .map((item) => ({ ...item, disabled: false }))
  } else if (itemsCount > 16) {
    displayItems = items
      .slice(0, 5)
      .map((item) => ({ ...item, disabled: false }))
  }

  return {
    itemsCount,
    displayMore,
    displayItems,
    displayModal,
  }
}

interface DesktopCategoricalFilterProps
  extends Omit<CategoricalFilterProps, 'onChange'> {
  handleOnlyClick: (key: string) => void
  handleModalOnlyClick: (key: string) => void
  filterName: string
  handleModalChange: (key: string) => void
  handleChange: (key: string) => void
  resetFilters: () => void
  submitModal: () => void
  modalSelected: Set<string>
}

interface MobileCategoricalFilterProps
  extends Omit<CategoricalFilterProps, 'onChange'> {
  handleOnlyClick: (key: string) => void
  handleChange: (key: string) => void
}

const DesktopCategoricalFilter = ({
  items,
  availableItems,
  selected,
  modalSelected,
  customModal,
  handleOnlyClick,
  handleModalOnlyClick,
  handleChange,
  handleModalChange,
  filterName,
  submitModal,
  resetFilters,
}: DesktopCategoricalFilterProps) => {
  const [showMore, setShowMore] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const { displayMore, displayItems, displayModal } = splitItems(
    items,
    availableItems
  )

  const handleShowMore = () => {
    if (!showMore) {
      setShowModal(true)
    }
    setShowMore((showMore) => !showMore)
  }

  const cancelShowMore = () => {
    resetFilters()
    setShowMore(false)
  }

  const handleModalClose = () => {
    resetFilters()
    setShowMore(false)
  }

  return (
    <>
      <List>
        {displayItems.map(({ displayName, key }) => (
          <OnlyableCheckbox
            key={key}
            displayName={displayName}
            checked={selected.has(key)}
            onChange={() => handleChange(key)}
            onOnly={() => handleOnlyClick(key)}
            //disabled={disabled}
          />
        ))}

        {showMore &&
          displayMore.map(({ displayName, key }) => (
            <OnlyableCheckbox
              key={key}
              displayName={displayName}
              checked={selected.has(key)}
              onChange={() => handleChange(key)}
              onOnly={() => handleOnlyClick(key)}
            />
          ))}
        {items.length > 5 && (
          <SeeMoreButton showMore={showMore} handleShowMore={handleShowMore} />
        )}
      </List>
      {displayModal &&
        showMore &&
        showModal &&
        (customModal ? (
          customModal(handleModalClose)
        ) : (
          <Modal closeModal={handleModalClose}>
            <Header>{filterName}</Header>
            <ModalContent>
              <ModalList>
                {items.map(({ displayName, key }) => (
                  <OnlyableCheckbox
                    key={key}
                    displayName={displayName}
                    checked={modalSelected.has(key)}
                    onChange={() => handleModalChange(key)}
                    onOnly={() => handleModalOnlyClick(key)}
                  />
                ))}
              </ModalList>
            </ModalContent>
            <ModalStatistic>
              <TotalSelected>
                <FormattedMessage
                  id={'Filters.modal.list'}
                  values={{
                    amount: modalSelected.size,
                    name: filterName,
                  }}
                />
              </TotalSelected>
              <SelectedCategories>
                {Array.from(modalSelected).join(', ')}
              </SelectedCategories>
            </ModalStatistic>
            <Modal.Actions
              submitMessage={<FormattedMessage id={'Common.showResult'} />}
              cancelMessage={<FormattedMessage id={'Common.cancel'} />}
              onCancel={cancelShowMore}
              onSubmit={() => {
                submitModal()
                cancelShowMore()
              }}
            />
          </Modal>
        ))}
    </>
  )
}

type AplabetItemType = {
  [key: string]: number
}

const aplabetItems = (items: RecordType[]) => {
  const result: AplabetItemType = {}
  Object.keys(items).forEach((_recordKey, index) => {
    result[items[index].key] = index
  })
  return result
}

const MobileCategoricalFilter = ({
  items,
  selected,
  handleChange,
  handleOnlyClick,
}: MobileCategoricalFilterProps) =>
  items.length <= 16 ? (
    <List>
      {items.map(({ displayName, key }) => (
        <OnlyableCheckbox
          key={key}
          displayName={displayName}
          checked={selected.has(key)}
          onChange={() => handleChange(key)}
          onOnly={() => handleOnlyClick(key)}
        />
      ))}
    </List>
  ) : (
    <AlphabeticalFilterContent
      items={aplabetItems(items)}
      selected={selected}
      handleChange={handleChange}
      handleOnlyClick={handleOnlyClick}
    />
  )

const CategoricalFilter: React.FC<CategoricalFilterProps> = ({
  items,
  availableItems,
  selected,
  onChange,
  name,
  customModal,
  desktopScreen,
}) => {
  const [updatedFilters, setUpdatedFilter] = useState<Set<string>>(selected)
  useEffect(() => {
    setUpdatedFilter(selected)
  }, [selected])
  const handleChange = (key: string) => {
    const clone = new Set([...(selected ? selected : [])])
    if (clone.has(key)) {
      clone.delete(key)
    } else {
      clone.add(key)
    }
    onChange(clone)
  }

  const handleModalChange = (key: string) => {
    const clone = new Set([...(updatedFilters ? updatedFilters : [])])
    if (clone.has(key)) {
      clone.delete(key)
    } else {
      clone.add(key)
    }
    setUpdatedFilter(clone)
  }

  const submitModal = () => {
    onChange(updatedFilters)
  }

  const handleOnlyClick = (key: string) => {
    const clone = new Set([...(selected ? selected : [])])

    clone.clear()
    clone.add(key)
    onChange(clone)
  }

  const handleModalOnlyClick = (key: string) => {
    const clone = new Set([...(updatedFilters ? updatedFilters : [])])

    clone.clear()
    clone.add(key)
    setUpdatedFilter(clone)
  }

  const resetFilters = () => {
    setUpdatedFilter(selected)
  }

  const filterName = name ? name.replace(/filter/gi, '').toLowerCase() : ''

  return desktopScreen ? (
    <DesktopCategoricalFilter
      items={items}
      availableItems={availableItems}
      selected={selected}
      modalSelected={updatedFilters}
      resetFilters={resetFilters}
      name={name}
      customModal={customModal}
      filterName={filterName}
      handleOnlyClick={handleOnlyClick}
      handleModalOnlyClick={handleModalOnlyClick}
      handleChange={handleChange}
      handleModalChange={handleModalChange}
      submitModal={submitModal}
    />
  ) : (
    <MobileCategoricalFilter
      items={items}
      availableItems={availableItems}
      selected={selected}
      handleChange={handleChange}
      name={name}
      handleOnlyClick={handleOnlyClick}
    />
  )
}

export default CategoricalFilter

export type { RecordType }
