import { useIntl } from 'react-intl'

import CategoricalFilter from '../../shared/CategoricalFilter'
import {
  useFilterOptions,
  createCategoricalAppliedComponent,
} from '../../shared/filterUtils'
import type { AuctionFilter } from '../../types'

const ManufacturerFilter: AuctionFilter<Set<string>> = ({
  onChange,
  desktopScreen,
  items: itemsData,
  availableItems: availableItemsData,
  value,
}) => {
  const intl = useIntl()
  const items = useFilterOptions(itemsData)
  const availableItems = useFilterOptions(availableItemsData)

  return (
    <CategoricalFilter
      items={items}
      availableItems={availableItems}
      selected={value}
      name={intl.formatMessage({
        id: 'Filters.manufacturer',
      })}
      onChange={onChange}
      desktopScreen={desktopScreen}
    />
  )
}

ManufacturerFilter.getOwnProps = ({ state, data, updateFilters }) => {
  return {
    items: data?.allFilters?.Manufacturer || {},
    availableItems: data?.availableFilters?.Manufacturer || {},
    value: new Set(state.filters.manufacturer),
    onChange: (value: Set<string>) =>
      updateFilters({ manufacturer: [...value] }),
  }
}

ManufacturerFilter.label = 'Filters.manufacturer'

ManufacturerFilter.Applied = createCategoricalAppliedComponent(
  'manufacturer',
  ManufacturerFilter.label,
  (manufacturer: string) => <span>{manufacturer}</span>
)

export default ManufacturerFilter
