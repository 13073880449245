import type { AuctionFiltersState } from '@components/AuctionSearchProvider'
import type { AuctionFilterList } from '@components/SearchFilters'

interface IProps {
  filtersState: AuctionFiltersState
  filterComponents: AuctionFilterList
  updateFilters: (filters: Partial<AuctionFiltersState>) => void
  noClose?: boolean
}

export const FiltersList = ({
  filterComponents,
  filtersState,
  updateFilters,
  noClose,
}: IProps) => {
  return (
    <>
      {filterComponents.map((FilterComp) =>
        FilterComp.Applied ? (
          <FilterComp.Applied
            key={FilterComp.label}
            filtersState={filtersState}
            updateFilters={updateFilters}
            noClose={noClose}
          />
        ) : null
      )}
    </>
  )
}
