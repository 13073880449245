import type * as React from 'react'

import { styled } from 'styled-components'

import { Spinner } from '@b-stock/bstock-react'

const Wrapper = styled.div`
  position: relative;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1;
`

type Props = {
  showSpinner?: boolean
  children?: React.ReactNode
  ariaLive?: 'off' | 'polite' | 'assertive'
}

const SpinnerWrapper = ({
  showSpinner,
  children,
  // Default to assertive on the assumptiuon that the CTA that triggers the
  // spinner state is within the
  ariaLive = 'assertive',
}: Props) => {
  return (
    <Wrapper
      aria-live={ariaLive}
      aria-busy={showSpinner ? 'true' : 'false'}
      data-testid="spinner-wrapper"
    >
      {showSpinner ? (
        <Overlay data-testid="spinner-overlay">
          <Spinner />
        </Overlay>
      ) : null}

      <div aria-hidden={showSpinner ? 'true' : 'false'}>{children}</div>
    </Wrapper>
  )
}

export default SpinnerWrapper
