import type * as React from 'react'

import { styled } from 'styled-components'

import { Checkbox } from '@b-stock/bstock-react'

const StyledCheckbox = styled(Checkbox)`
  overflow: hidden;
  & span:first-child {
    margin-right: 5px;
  }
  & span:nth-child(2) {
    text-transform: capitalize;
  }

  align-items: flex-start;

  & span {
    line-height: 1rem;
  }
`

type FilterCheckboxProps = Omit<
  React.ComponentPropsWithoutRef<typeof Checkbox>,
  'size'
>

const FilterCheckbox: React.FC<FilterCheckboxProps> = (props) => {
  return <StyledCheckbox size={14} {...props} checkTruncated />
}

export default FilterCheckbox
