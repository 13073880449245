import { useCallback, useMemo, useRef, useState } from 'react'

import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import type { CheckboxTreeBranchNode } from '@b-stock/bstock-react'
import {
  CheckboxTreeNodeType,
  Button,
  CheckboxTree,
  FormattedMessage,
  Modal,
  getCategoriesFromSubcategories as getCategoriesFromSubcategoriesEnum,
  getSubcategoryHierarchy as getSubcategoryHierarchyEnum,
} from '@b-stock/bstock-react'
import { ListingEnums } from '@b-stock/bstock-react/locale'
import { designColors, pxToRem, shadows } from '@b-stock/bstock-react/theme'
import type {
  ItemCategoriesDtoCategoriesEnum as CategoriesEnumType,
  ItemCategoriesDtoSubcategoriesEnum as SubcategoriesEnumType,
} from '@b-stock/listing-api-client'
import {
  ItemCategoriesDtoCategoriesEnum as CategoriesEnum,
  ItemCategoriesDtoSubcategoriesEnum as SubcategoriesEnum,
} from '@b-stock/listing-api-client'

import {
  Header,
  ModalStatistic,
  TotalSelected,
} from '@components/SearchFilters/shared/Modal'
import SeeMoreButton from '@components/SearchFilters/shared/SeeMoreButton'

import {
  useFilterOptions,
  createCategoricalAppliedComponent,
} from '../../shared/filterUtils'
import type { AuctionFilter, FilterProps } from '../../types'

const MAX_NODES_IN_SIDEBAR = 6

function isNotNullish<T>(x: T | null | undefined): x is T {
  return x !== null && x !== undefined
}

const ALL_SUBCATEGORIES = Object.values(SubcategoriesEnum).reduce<
  Partial<Record<SubcategoriesEnumType, boolean>>
>((acc, subcategory: SubcategoriesEnumType) => {
  if (acc[subcategory] === undefined) {
    acc[subcategory] = true
  }
  return acc
}, {})

function isSubcategory(
  str: SubcategoriesEnumType | CategoriesEnumType
): str is SubcategoriesEnumType {
  return str in ALL_SUBCATEGORIES
}

const FilterWrapper = styled.div`
  padding-left: ${pxToRem(6)};
  padding-bottom: ${pxToRem(10)};
`

const ModalContent = styled(Modal.Content)`
  width: 43.75rem;
`

const ModalActionsContainer = styled.div`
  ${shadows.top}
  border-top: 0.0625rem solid ${designColors.neutral.mediumGray};
`

const ModalActions = styled.div`
  padding: 2rem 3rem;
  display: flex;
  column-gap: 2.25rem;
  justify-content: flex-end;
`

const CategoryTreeFilter: AuctionFilter<Set<string>> = ({
  onChange,
  availableItems: availableItemsData,
  value,
}: FilterProps<Set<string>>) => {
  // "maybe" subcategories because at this point they might be subcategories (for new listings)
  // or top-level categories (for listings created before the switch to subcategories)
  const availableMaybeSubcategories = useFilterOptions(availableItemsData)

  // We have to implement a wrapper on top of the existing `getCategoriesFromSubcategories`
  // because the buyer portal filters work differently than others. In other portals, the value
  // we send to the server (and that the server sends back to us) is the constant from the
  // (sub)categories enum. However, buyer portal uses the English name of the (sub)category.
  // So we have to convert the subcategory names from their english names to enum values, then call
  // `getCategoriesFromSubcategories` to get the parent categories (as enum values), then convert
  // those back to the English names.
  // Most of the code in this component (everything from `useIntl` to the place where we actually call the wrapped
  // `getCategoriesFromSubcategories`) is part of that implementation.
  const intl = useIntl()

  // this is named "maybeSubcategoryEnumToTranslation" because `ListingEnums.Subcategory` includes
  // translations for top-level categories as well -- so it already handles the case of listings
  // created with top-level categories as their subcategories. (However, it doesn't handle the case
  // of top-level categories that were renamed, so that is handled separately below.)
  const maybeSubcategoryEnumToTranslation = useCallback(
    (enumValue: SubcategoriesEnumType | CategoriesEnumType) =>
      ListingEnums.Subcategory.format(intl.formatMessage, enumValue),
    [intl.formatMessage]
  )

  const categoryEnumToTranslation = useCallback(
    (enumValue: CategoriesEnumType) =>
      ListingEnums.Category.format(intl.formatMessage, enumValue),
    [intl.formatMessage]
  )

  const [maybeSubcategoryEnumToStringMap, maybeSubcategoryStringToEnumMap] =
    useMemo(() => {
      const [tempEnumToString, tempStringToEnum] = Object.values(
        SubcategoriesEnum
      ).reduce(
        ([enumToString, stringToEnum], subcategoryEnum) => {
          const translation = maybeSubcategoryEnumToTranslation(subcategoryEnum)
          enumToString.set(subcategoryEnum, translation)
          stringToEnum.set(translation, subcategoryEnum)
          return [enumToString, stringToEnum]
        },
        [
          new Map<SubcategoriesEnumType | CategoriesEnumType, string>(),
          new Map<string, SubcategoriesEnumType | CategoriesEnumType>(),
        ]
      )

      // Add top-level categories as possible "subcategories"
      // This can be removed once there are no more active listings using top-level categories
      // as their (sub)categories
      Object.values(CategoriesEnum).forEach((categoryEnum) => {
        const translation = maybeSubcategoryEnumToTranslation(categoryEnum)
        tempEnumToString.set(categoryEnum, translation)
        tempStringToEnum.set(translation, categoryEnum)
      })

      return [tempEnumToString, tempStringToEnum]
    }, [])

  const getCategoriesFromSubcategories = useCallback(
    (translatedItems: string[]) => {
      const subcategories = translatedItems.reduce<
        (SubcategoriesEnumType | CategoriesEnumType)[]
      >((acc, item) => {
        const subcategoryEnum = maybeSubcategoryStringToEnumMap.get(item)
        if (isNotNullish(subcategoryEnum)) {
          acc.push(subcategoryEnum)
        }
        return acc
      }, [])

      const categories = getCategoriesFromSubcategoriesEnum(subcategories)
      return categories.reduce<
        { catStr: string; catEnum: CategoriesEnumType }[]
      >((acc, categoryEnum) => {
        const catStr = maybeSubcategoryEnumToStringMap.get(categoryEnum)
        if (isNotNullish(catStr)) {
          acc.push({
            catStr,
            catEnum: categoryEnum,
          })
        }

        return acc
      }, [])
    },
    [maybeSubcategoryStringToEnumMap, maybeSubcategoryEnumToStringMap]
  )

  const availableCategories = useMemo(
    () =>
      getCategoriesFromSubcategories(
        availableMaybeSubcategories.map((item) => item.key)
      ),
    [getCategoriesFromSubcategories, availableMaybeSubcategories]
  )

  const subcategoryHierarchy = useMemo(() => {
    const modernHierarchy = getSubcategoryHierarchyEnum()
    const fallbackHierarchy: Record<
      CategoriesEnum,
      (SubcategoriesEnum | CategoriesEnum | typeof CategoriesEnum.Other)[]
    > = {
      ...modernHierarchy,
      // Temporarily add pointers for top-level categories that became subcategories
      // This can be removed once there are no more active listings using the top-level categories
      // that were moved into other categories
      [CategoriesEnum.Tvs]: modernHierarchy[CategoriesEnum.ConsumerElectronics],
      [CategoriesEnum.ComputerEquipmentAndSoftware]:
        modernHierarchy[CategoriesEnum.ConsumerElectronics],
      // Temporarily add a top-level category and subcategory for the deprecated "Other" category
      // This can be removed once there are no more active listings under category "Other"
      [CategoriesEnum.Other]: [CategoriesEnum.Other],
    }

    // Temporarily include top-level categories as a subcategory within their own category
    // This can be removed once there are no more active listings using top-level categories
    // as their (sub)categories
    availableMaybeSubcategories.forEach((item) => {
      const categoryEnum = maybeSubcategoryStringToEnumMap.get(item.key)
      if (
        categoryEnum !== undefined &&
        !isSubcategory(categoryEnum) &&
        !fallbackHierarchy[categoryEnum].includes(categoryEnum)
      ) {
        fallbackHierarchy[categoryEnum].unshift(categoryEnum)
      }
    })

    return fallbackHierarchy
  }, [availableMaybeSubcategories, maybeSubcategoryStringToEnumMap])

  const availableNodes = useMemo(
    () =>
      availableCategories.map(({ catStr, catEnum }) => {
        return {
          nodeType: CheckboxTreeNodeType.BRANCH,
          key: `category-${catStr}`,
          displayName: categoryEnumToTranslation(catEnum),
          // "Mixed Lots" and "Other" should never show a subcategory
          expand:
            catEnum !== CategoriesEnum.MixedLots &&
            catEnum !== CategoriesEnum.Other,
          // get all the subcategories for the current category
          // then filter out the ones that won't have any matches
          // and only create child nodes for those
          children: subcategoryHierarchy[catEnum]
            .filter((subcategory) => {
              const subcategoryName =
                maybeSubcategoryEnumToStringMap.get(subcategory)
              return (
                subcategoryName !== undefined &&
                availableItemsData !== undefined &&
                subcategoryName in availableItemsData
              )
            })
            .map((subcategory) => {
              return {
                nodeType: CheckboxTreeNodeType.LEAF,
                key: maybeSubcategoryEnumToTranslation(subcategory),
                displayName: maybeSubcategoryEnumToTranslation(subcategory),
              }
            }),
        }
      }),
    [availableCategories]
  )

  const [showModal, setShowModal] = useState<boolean>(false)
  const modalNodesExpandedState = useRef<Record<string, boolean>>({})
  const enableSeeMore = availableNodes.length > MAX_NODES_IN_SIDEBAR
  const minimalAvailableNodes = useMemo(
    () => availableNodes.slice(0, MAX_NODES_IN_SIDEBAR),
    [availableNodes]
  )
  const sortedNodes = useMemo(
    () =>
      [...availableNodes].sort((a, b) => {
        return a.displayName < b.displayName ? -1 : 1
      }),
    [availableNodes]
  )

  const handleSeeMore = () => {
    setShowModal(true)
  }

  const handleModalClose = (expandedState: Record<string, boolean>) => {
    modalNodesExpandedState.current = expandedState
    setShowModal(false)
  }

  const handleModalCancel = (expandedState: Record<string, boolean>) => {
    modalNodesExpandedState.current = expandedState
    setShowModal(false)
  }

  const handleModalSubmit = (
    values: Set<string>,
    expandedState: Record<string, boolean>
  ) => {
    modalNodesExpandedState.current = expandedState
    onChange(values)
    setShowModal(false)
  }

  return (
    <>
      <FilterWrapper>
        <CheckboxTree
          nodes={minimalAvailableNodes}
          values={value}
          onChange={onChange}
        />
      </FilterWrapper>

      {enableSeeMore && (
        <SeeMoreButton showMore={false} handleShowMore={handleSeeMore} />
      )}

      {showModal && (
        <FilterModal
          nodes={sortedNodes}
          values={value}
          onClose={handleModalClose}
          onCancel={handleModalCancel}
          onSubmit={handleModalSubmit}
          nodesInitialExpandedState={modalNodesExpandedState.current}
        />
      )}
    </>
  )
}

CategoryTreeFilter.getOwnProps = ({ state, data, updateFilters }) => {
  return {
    items: data?.allFilters.Category || {},
    availableItems: data?.availableFilters.Category || {},
    value: new Set(state.filters.category),
    onChange: (value: Set<string>) => updateFilters({ category: [...value] }),
  }
}

CategoryTreeFilter.label = 'Filters.category'

CategoryTreeFilter.Applied = createCategoricalAppliedComponent(
  'category',
  CategoryTreeFilter.label,
  (category: string) => <span>{category}</span>
)

export default CategoryTreeFilter

type CategoryTreeFilterModalProps = {
  nodes: CheckboxTreeBranchNode[]
  values: Set<string>
  onClose: (expandedState: Record<string, boolean>) => void
  onCancel: (expandedState: Record<string, boolean>) => void
  onSubmit: (
    values: Set<string>,
    expandedState: Record<string, boolean>
  ) => void
  nodesInitialExpandedState: Record<string, boolean>
}

function FilterModal({
  nodes,
  values,
  onClose,
  onCancel,
  onSubmit,
  nodesInitialExpandedState,
}: CategoryTreeFilterModalProps) {
  const [internalValues, setInternalValues] = useState(values)
  const [expandedState, setExpandedState] = useState<Record<string, boolean>>(
    nodesInitialExpandedState
  )
  const handleChange = useCallback(
    (value: Set<string>) => {
      setInternalValues(value)
    },
    [setInternalValues]
  )

  const handleNodeExpandedChange = useCallback(
    (key: string, expanded: boolean) => {
      setExpandedState({
        ...expandedState,
        [key]: expanded,
      })
    },
    [expandedState, setExpandedState]
  )

  const shouldNodeBeExpanded = (node: CheckboxTreeBranchNode) => {
    return expandedState[node.key] ?? false
  }

  const handleModalClose = useCallback(() => {
    onClose(expandedState)
  }, [onClose, expandedState])

  const handleCancelClick = useCallback(() => {
    onCancel(expandedState)
  }, [onCancel, expandedState])

  const handleSubmitClick = useCallback(() => {
    onSubmit(internalValues, expandedState)
  }, [onSubmit, internalValues, expandedState])

  return (
    <Modal closeModal={handleModalClose}>
      <Header>
        <FormattedMessage id="Filters.category" />
      </Header>

      <ModalContent>
        <CheckboxTree
          nodes={nodes}
          values={internalValues}
          onChange={handleChange}
          splitToColumns
          onBranchExpandedChange={handleNodeExpandedChange}
          shouldNodeBeExpanded={shouldNodeBeExpanded}
        />
      </ModalContent>

      <ModalActionsContainer>
        <ModalStatistic>
          <TotalSelected>
            <FormattedMessage
              id="Filters.modal.selectedCategoryCount"
              values={{
                count: internalValues.size,
              }}
            />
          </TotalSelected>
        </ModalStatistic>

        <ModalActions>
          <Button appearance="plainTertiary" onClick={handleCancelClick}>
            <FormattedMessage id={'Common.cancel'} />
          </Button>

          <Button onClick={handleSubmitClick}>
            <FormattedMessage id={'Common.showResult'} />
          </Button>
        </ModalActions>
      </ModalActionsContainer>
    </Modal>
  )
}
