import { useIntl } from 'react-intl'

import { ListingEnums } from '@b-stock/bstock-react/locale'
import type { AccessoriesDtoAccessoriesEnum } from '@b-stock/listing-api-client' // TODO: update

import CategoricalFilter from '../../shared/CategoricalFilter'
import {
  useFilterOptions,
  createCategoricalAppliedComponent,
} from '../../shared/filterUtils'
import type { AuctionFilter } from '../../types'

const toDisplayName = (option: string) => (
  <ListingEnums.Accessories.Component
    // We know the option will be an enum value in practice, even though
    // the API doesn't strictly enforce it. If it ever isn't, ListingEnums
    // should gracefully handle this and just output the raw option string.
    value={option as AccessoriesDtoAccessoriesEnum}
  />
)
const AccessoriesFilter: AuctionFilter<Set<string>> = ({
  onChange,
  desktopScreen,
  items: itemsData,
  availableItems: availableItemsData,
  value,
}) => {
  const intl = useIntl()
  const items = useFilterOptions(itemsData, toDisplayName)
  const availableItems = useFilterOptions(availableItemsData, toDisplayName)

  return (
    <CategoricalFilter
      items={items}
      availableItems={availableItems}
      selected={value}
      name={intl.formatMessage({
        id: 'Filters.accessories',
      })}
      onChange={onChange}
      desktopScreen={desktopScreen}
    />
  )
}

AccessoriesFilter.getOwnProps = ({ state, data, updateFilters }) => {
  return {
    items: data?.allFilters.Accessories || {},
    availableItems: data?.availableFilters.Accessories || {},
    value: new Set(state.filters.accessories),
    onChange: (value: Set<string>) =>
      updateFilters({ accessories: [...value] }),
  }
}

AccessoriesFilter.label = 'Filters.accessories'

AccessoriesFilter.Applied = createCategoricalAppliedComponent(
  'accessories',
  AccessoriesFilter.label,
  toDisplayName
)

export default AccessoriesFilter
