import { styled } from 'styled-components'

import { useAccessToken } from '@b-stock/bstock-next'

import FilterContainer from './shared/FilterContainer'
import type { Filter, ContextType } from './types'

type SearchFiltersProps<ContextValue extends ContextType, T> = {
  className?: string
  filters: Filter<T, ContextValue>[]
  searchCtx: ContextValue
}

const Container = styled.div`
  position: relative;
`

const SearchFilters = <ContextValue extends ContextType, T>({
  className,
  filters,
  searchCtx,
}: SearchFiltersProps<ContextValue, T>) => {
  // TODO: implement saved search
  // const authState = useAuthState()
  const accessToken = useAccessToken()
  const isLoggedIn = !!accessToken

  return (
    <Container className={className} data-testid="SearchFilters">
      {filters.map((FilterComp) => {
        const props = FilterComp.getOwnProps(searchCtx)

        // TODO: review it, The applied filters checking should be refactored
        const shouldDisplayFilter =
          Object.keys(props.availableItems || {}).length ||
          Object.keys(props.value || {}).length ||
          props.value === null || // it is empty range value
          Object.keys(props.items || {}).length

        const showForCurrentUser = props.forLoggedUsersOnly ? isLoggedIn : true
        const propsOnChange = props.onChange
        props.onChange = (value) => {
          propsOnChange(value)
          searchCtx.setPage(1)
        }
        return shouldDisplayFilter && showForCurrentUser ? (
          <FilterContainer
            key={FilterComp.label}
            label={FilterComp.label}
            expandedOnInit
          >
            <FilterComp {...props} desktopScreen />
          </FilterContainer>
        ) : null
      })}
    </Container>
  )
}

export default SearchFilters
