import { createNumericRangeAppliedComponent } from '../../shared/filterUtils'
import type { Range } from '../../shared/NumericRangeFilter'
import NumericRangeFilter, {
  USDFormat,
  emptyRange,
} from '../../shared/NumericRangeFilter'
import type { AuctionFilter } from '../../types'

type ValueType = Range

const ranges: Range[] = [
  [0, 300],
  [300, 500],
  [500, 1000],
  [1000, 5000],
  [5000, 50000],
  [50000, 100000],
  [100000, null],
]

const CurrentBidFilter: AuctionFilter<ValueType> = ({ onChange, value }) => (
  <NumericRangeFilter
    items={ranges}
    selected={value}
    onChange={onChange}
    formatRange={USDFormat}
  />
)

CurrentBidFilter.getOwnProps = ({ state, updateFilters }) => ({
  onChange: (currentBidRanges: ValueType) => {
    updateFilters({ currentBidRanges })
  },
  value: state.filters.currentBidRanges || emptyRange,
  forLoggedUsersOnly: true,
})

CurrentBidFilter.label = 'Filters.currentBid'

CurrentBidFilter.Applied = createNumericRangeAppliedComponent(
  'currentBidRanges',
  CurrentBidFilter.label
)

export default CurrentBidFilter
