import { createCurrencyRangeAppliedComponent } from '../../shared/filterUtils'
import type { Range, LeftClosedRange } from '../../shared/NumericRangeFilter'
import NumericRangeFilter, { MSRPFormat } from '../../shared/NumericRangeFilter'
import type { AuctionFilter } from '../../types'

type ValueType = Range

const ranges: LeftClosedRange[] = [
  [0, 100000],
  [100000, 500000],
  [500000, 2500000],
  [2500000, 10000000],
  [10000000, 50000000],
  [50000000, null],
]

const RetailValueFilter: AuctionFilter<ValueType> = ({ onChange, value }) => (
  <NumericRangeFilter
    items={ranges}
    selected={value}
    onChange={onChange}
    formatRange={MSRPFormat}
    treatAsCents
  />
)

RetailValueFilter.getOwnProps = ({ state, updateFilters }) => {
  return {
    onChange: (retailValueRanges: ValueType) => {
      updateFilters({ retailValueRanges })
    },
    value: state.filters.retailValueRanges,
  }
}

RetailValueFilter.label = 'Filters.retailValue'

RetailValueFilter.Applied = createCurrencyRangeAppliedComponent(
  'retailValueRanges',
  RetailValueFilter.label
)

export default RetailValueFilter
