import { useIntl } from 'react-intl'

import CategoricalFilter from '../../shared/CategoricalFilter'
import {
  useFilterOptions,
  createCategoricalAppliedComponent,
} from '../../shared/filterUtils'
import type { AuctionFilter } from '../../types'

const ProductTypeFilter: AuctionFilter<Set<string>> = ({
  onChange,
  desktopScreen,
  items: itemsData,
  availableItems: availableItemsData,
  value,
}) => {
  const intl = useIntl()
  const items = useFilterOptions(itemsData)
  const availableItems = useFilterOptions(availableItemsData)

  return (
    <CategoricalFilter
      items={items}
      availableItems={availableItems}
      selected={value}
      name={intl.formatMessage({
        id: 'Filters.productType',
      })}
      onChange={onChange}
      desktopScreen={desktopScreen}
    />
  )
}

ProductTypeFilter.getOwnProps = ({ state, data, updateFilters }) => {
  return {
    items: data?.allFilters?.['Product Type'] || {},
    availableItems: data?.availableFilters?.['Product Type'] || {},
    value: new Set(state.filters.productType),
    onChange: (value: Set<string>) =>
      updateFilters({ productType: [...value] }),
  }
}

ProductTypeFilter.label = 'Filters.productType'

ProductTypeFilter.Applied = createCategoricalAppliedComponent(
  'productType',
  ProductTypeFilter.label,
  (productType: string) => <span>{productType}</span>
)

export default ProductTypeFilter
