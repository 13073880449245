import { useRef } from 'react'

import { faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { UnstyledButton } from '@b-stock/bstock-next'
import { FormattedMessage } from '@b-stock/bstock-react'
import { designColors, typography, shadows } from '@b-stock/bstock-react/theme'

import { useAuctionSearch } from '@components/AuctionSearchProvider'
import type { AuctionFilterList } from '@components/SearchFilters'

import { FiltersList } from './FiltersList'

type AppliedFilterProps = {
  filters: AuctionFilterList
}

const Wrapper = styled.div`
  grid-area: applied;
  overflow-x: hidden;
  max-width: 100%;
  margin-bottom: 0.9375rem;
`

const ScrollContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: 100%;
  padding-bottom: 0.5rem;
  padding-right: 2.5rem;
`

const ActiveBar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CounterWrapper = styled.div`
  display: flex;
`

const Counter = styled.div`
  ${typography.subtitle1};
  color: ${designColors.neutral.black};
`

const ResetBtn = styled(UnstyledButton)`
  ${typography.subtitle3};
  color: ${designColors.primary.default};
  margin-left: 2.1875rem;
`

const ScrollBtn = styled(UnstyledButton)`
  position: absolute;
  right: 0;
  bottom: -3.75rem;
  width: 2.5rem;
  height: 2.5rem;
  background-color: white;
  ${shadows.popovers}
  border-radius: 100%;
  z-index: 9999;
  margin: 0.3125rem;
`

export const AppliedFilters = ({ filters }: AppliedFilterProps) => {
  const { state, updateFilters, getActiveFilterCount, resetFilters } =
    useAuctionSearch()
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const activeFilterCount = getActiveFilterCount()

  const scrollRight = () => {
    const element = scrollContainerRef.current
    if (element) {
      const newPosition = element.scrollLeft + 50
      element.scrollTo({
        left: newPosition,
        behavior: 'smooth',
      })
    }
  }

  if (!activeFilterCount) return null

  return (
    <Wrapper data-testid="wrapper">
      <ActiveBar>
        <CounterWrapper>
          <Counter>
            <FormattedMessage
              id="Applied.filter.counter"
              values={{
                counter: activeFilterCount.toString(),
              }}
            />
          </Counter>
          <ResetBtn onClick={resetFilters}>
            <FormattedMessage id={'Applied.filter.reset'} />
          </ResetBtn>
        </CounterWrapper>
        {
          // These accesses of `scrollContainerRef` aren't safe... but are "good enough" for now
          // eslint-disable-next-line react-compiler/react-compiler
          scrollContainerRef.current &&
            // eslint-disable-next-line react-compiler/react-compiler
            scrollContainerRef.current.offsetWidth <
              // eslint-disable-next-line react-compiler/react-compiler
              scrollContainerRef.current.scrollWidth && (
              <ScrollBtn onClick={scrollRight}>
                <FontAwesomeIcon icon={faAngleRight} />
              </ScrollBtn>
            )
        }
      </ActiveBar>
      <ScrollContainer ref={scrollContainerRef}>
        <FiltersList
          filterComponents={filters}
          filtersState={state.filters}
          updateFilters={updateFilters}
        />
      </ScrollContainer>
    </Wrapper>
  )
}
