import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled, css } from 'styled-components'

import { Button, Tag } from '@b-stock/bstock-react'
import { designColors, typography, shadows } from '@b-stock/bstock-react/theme'

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
`

export const Price = styled.div<{
  $won: boolean
  $lost: boolean
}>`
  ${typography.title3};
  color: ${({ $won, $lost }) =>
    designColors.neutral[$won || $lost ? 'darkGray' : 'black']};
`

export const TagList = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  gap: 0.5rem;
  padding: 0;
`

export const SalvageTag = styled(Tag)`
  &&& {
    background: ${designColors.warning.tag};
    color: ${designColors.neutral.black};
    border: unset;
  }
`

export const SalvageIcon = styled(FontAwesomeIcon)`
  &&& {
    margin-right: 0.2rem;
  }
`

export const LocationInfo = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  ${typography.body4}
`

export const LocationIcon = styled(FontAwesomeIcon)`
  color: #424a58;
  background: white;
`

export const AddToWatchlist = styled(FontAwesomeIcon)`
  color: ${designColors.neutral.black};
  ${typography.body3}
`

export const Title = styled.span`
  ${typography.body2}
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
  cursor: pointer;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.5rem;
  margin: -0.5rem;
  width: 15.75rem;
  height: 31rem;
  cursor: pointer;

  &:hover {
    ${shadows.hover}
  }
`

export const CardDescription = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0 0;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 14.75rem;
  cursor: pointer;
`

export const AuctionImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`

export const ActionButton = styled(Button)`
  &&& {
    grid-area: actions;
    margin: 0;
    color: ${designColors.neutral.veryDarkGray};
    border: 1px solid ${designColors.neutral.veryDarkGray};
  }
`

export const BidAmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export type BidAmountProps = {
  $losing?: boolean
}

export const BidAmount = styled.div<BidAmountProps>`
  ${typography.body3}
  color: ${(o) =>
    o.$losing ? designColors.error.highlight : designColors.success.highlight};
`

export const WinAt = styled(BidAmount)`
  ${typography.subtitle2}
`

export const Msrp = styled.div`
  position: relative;
  ${typography.caption};
  color: ${designColors.neutral.darkGray};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25rem;
`

export const MsrpAmount = styled.div`
  text-decoration: line-through;
`

export const BidsCountAndTime = styled.div`
  ${typography.subtitle2};
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  color: ${designColors.neutral.darkGray};
  font-weight: normal;
`

export const TimeLeftContainer = styled.div<{ $hot?: boolean }>`
  display: flex;
  color: ${({ $hot }) =>
    $hot ? designColors.warning.highlight : designColors.neutral.darkGray};

  ${({ $hot }) =>
    $hot &&
    css`
      margin-right: 0.3rem;
      ${typography.body3};
    `}
`

export const VerticalDivider = styled.div`
  border-right: 1px solid ${designColors.neutral.mediumGray};
  height: 1rem;
`

export const BidsCount = styled.div`
  ${typography.body3};
  display: flex;
  gap: 0.25rem;
  align-items: center;
`

type ChipsProps = {
  $defaultStatus?: boolean
}

export const Chips = styled.div<ChipsProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: ${(o) => (o.$defaultStatus ? 'flex-end' : 'flex-start')};
  gap: 0.5rem;
  z-index: 25;
  left: 0.5rem;
  right: 0.5rem;
  top: 0.5rem;
`

export const ImageOverlay = styled.div`
  position: absolute;
  bottom: 0;
  top: 70%;
  left: 0;
  right: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  display: flex;
  align-items: flex-end;
`

export const ImageFooter = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`

export const SoldByContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const SellerContainer = styled.div`
  color: ${designColors.neutral.white};
`

export const SoldByLabel = styled.div`
  ${typography.caption}
`

export const SellerName = styled.div`
  ${typography.subtitle1}
`
