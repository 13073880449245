// This entire file is deprecated
/* eslint-disable @typescript-eslint/no-deprecated */
import { styled } from 'styled-components'

import SearchPagination from '@components/SearchPagination'

/**
 * @deprecated Use the Pagination component from bstock-react
 */
export const PositionedSearchPagination = styled(SearchPagination)`
  grid-area: pagination;
`
