import { styled } from 'styled-components'

import { Modal } from '@b-stock/bstock-react'
import { designColors, typography } from '@b-stock/bstock-react/theme'

export const Header = styled(Modal.Header)`
  text-transform: capitalize;
`

export const ModalStatistic = styled.div`
  margin: 0 3rem;
  padding: 1rem 0;
  border-bottom: 0.0625rem solid ${designColors.neutral.mediumGray};
`

export const TotalSelected = styled.div`
  ${typography.subtitle2};
`
